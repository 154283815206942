<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Basic Details</h3>
      </v-col>
      <v-col>
        <v-row align="center" justify="end" no-gutters>
          <v-btn
            small
            depressed
            color="accent"
            class="mr-2"
            @click="openEditCustomer(customer)"
          >
            <v-icon left>mdi-pencil</v-icon>Edit
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <th>Company</th>
                <td>{{ customer.name }}</td>
              </tr>
              <tr>
                <th>Website</th>
                <td>{{ customer.website }}</td>
              </tr>
              <tr>
                <th>Relationship</th>
                <td>{{ customer.relationship }}</td>
              </tr>
              <tr>
                <th>Company Email</th>
                <td>{{ customer.company_email }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="6">
        <v-simple-table>
          <template v-slot:default>
            <tbody>
              <tr>
                <th>Sector</th>
                <td>{{ getSectorName(customer.sector) }}</td>
              </tr>
              <tr>
                <th>Vat Number</th>
                <td>{{ customer.vat_number }}</td>
              </tr>
              <tr>
                <th>Shortcode</th>
                <td>{{ customer.shortcode }}</td>
              </tr>
              <tr>
                <th>Notes</th>
                <td style="white-space: pre">{{ customer.notes }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editCustomerForm.dialog" max-width="600px">
      <v-card>
        <v-form @submit.prevent="editCustomer" method="post">
          <v-card-title>
            <span class="headline">Edit Customer</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Customer Name *"
                  type="text"
                  v-model="editCustomerForm.name"
                  outlined
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('name')"
                  :error-messages="editCustomerForm.errors['name']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Website"
                  type="text"
                  v-model="editCustomerForm.website"
                  outlined
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('website')"
                  :error-messages="editCustomerForm.errors['website']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-combobox
                  :items="sectors"
                  label="Sector"
                  v-model="editCustomerForm.sector"
                  item-text="name"
                  :return-object="false"
                  chips
                  outlined
                  dense
                  autocomplete="false"
                  :error="editCustomerForm.errors.hasOwnProperty('sector')"
                  :error-messages="editCustomerForm.errors['sector']"
                ></v-combobox>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="VAT Number"
                  type="text"
                  v-model="editCustomerForm.vat_number"
                  outlined
                  dense
                  :error="editCustomerForm.errors.hasOwnProperty('vat_number')"
                  :error-messages="editCustomerForm.errors['vat_number']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  label="Relationship"
                  :items="companyRelationships"
                  v-model="editCustomerForm.relationship"
                  item-text="label"
                  item-value="value"
                  outlined
                  dense
                  :error="
                    editCustomerForm.errors.hasOwnProperty('relationship')
                  "
                  :error-messages="editCustomerForm.errors['relationship']"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Short Code"
                  type="text"
                  v-model="editCustomerForm.shortcode"
                  outlined
                  dense
                  class="shortcode-input"
                  :error="editCustomerForm.errors.hasOwnProperty('shortcode')"
                  :error-messages="editCustomerForm.errors['shortcode']"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  label="Company Email"
                  type="text"
                  v-model="editCustomerForm.company_email"
                  outlined
                  dense
                  class
                  :error="
                    editCustomerForm.errors.hasOwnProperty('company_email')
                  "
                  :error-messages="editCustomerForm.errors['company_email']"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  label="Notes"
                  v-model="editCustomerForm.notes"
                  outlined
                  dense
                  class
                  :error="editCustomerForm.errors.hasOwnProperty('notes')"
                  :error-messages="editCustomerForm.errors['notes']"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="secondary"
              text
              @click="editCustomerForm.dialog = false"
              >Close</v-btn
            >
            <v-btn
              color="accent"
              text
              type="submit"
              :loading="editCustomerForm.loading"
              >Save</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      editCustomerForm: {
        dialog: false,
        id: null,
        name: null,
        website: null,
        sector: null,
        vat_number: null,
        relationship: null,
        company_email: null,
        notes: null,
        shortcode: null,
        loading: false,
        errors: {},
      },
      companyRelationships: [
        { value: "partner", label: "Partner", color: "success" },
        { value: "support", label: "Support", color: "warning" },
        { value: "ad-hoc", label: "Ad-hoc", color: "blue white--text" },
        { value: "left-us", label: "Left Us", color: "default" },
        { value: "oob", label: "OOB", color: "black white--text" },
        { value: "na", label: "N/A", color: "error" },
      ],
    };
  },

  computed: {
    sectors() {
      return this.$store.state.customers["sectors"];
    },
  },

  methods: {
    getSectorName(customerSector) {
      if (customerSector === null || customerSector === "") {
        return null;
      }

      const sector = this.sectors.find((s) => s.id === customerSector);
      return sector.name;
    },

    openEditCustomer: function (customer) {
      const sector = this.sectors.find((s) => s.id === customer.sector);

      this.editCustomerForm = {
        dialog: true,
        id: customer.id,
        name: customer.name,
        website: customer.website,
        sector: customer.sector !== null ? [sector.name] : null,
        vat_number: customer.vat_number,
        relationship: customer.relationship,
        shortcode: customer.shortcode,
        company_email: customer.company_email,
        notes: customer.notes,
        loading: false,
        errors: {},
      };
    },
    editCustomer: function () {
      const appId = this.$route.params.id;
      const customerId = this.editCustomerForm.id;
      const name = this.editCustomerForm.name;
      const website = this.editCustomerForm.website;
      const sector = this.editCustomerForm.sector;
      const vat_number = this.editCustomerForm.vat_number;
      const relationship = this.editCustomerForm.relationship;
      const shortcode = this.editCustomerForm.shortcode;
      const company_email = this.editCustomerForm.company_email;
      const notes = this.editCustomerForm.notes;

      this.editCustomerForm.loading = true;

      this.$store
        .dispatch("customers/editCustomer", {
          appId,
          customerId,
          name,
          website,
          sector,
          vat_number,
          relationship,
          shortcode,
          company_email,
          notes,
        })
        .then(() => {
          this.editCustomerForm = {
            dialog: false,
            id: null,
            name: null,
            website: null,
            sector: null,
            vat_number: null,
            relationship: null,
            shortcode: null,
            company_email: null,
            notes: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editCustomerForm.errors = err.response.data.errors;
          this.editCustomerForm.loading = false;
        });
    },
  },
};
</script>
